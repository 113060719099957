














































































































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { CreateVaultViewmodel } from '../../viewmodel/create-vault-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: CreateVaultViewmodel
  valid = false
}
